.ocr_person_card {
  border-bottom: 1px solid lightgray;
  width: 100%;
  padding: 0.5rem 0;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.ocr_person_card_info {
  margin-top: 0.4rem;
  width: 80%;
  text-align: justify;
}
.ocr_person_card_info div {
  width: 100%;
}
.ocr_person_card_info div > * {
  margin-top: 0.4rem;
}

.ocr_person_card_info h3 {
  text-align: center;
}
.ocr_person_card_info p {
  color: gray;
}

.ocr_person_card_info p span {
  color: black;
  font-weight: 700;
}
