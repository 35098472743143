.photo_button {
  margin-top: 12px;
  width: 100px;
  height: 100px;
  position: relative;
}

.circle {
  position: absolute;
  top: 12%;
  left: 12%;
  bottom: 12%;
  right: 12%;
  border-radius: 100%;
  background-color: #ffffff;
  filter: drop-shadow(0px 0px 10px #0000004e);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ring {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  border-radius: 100%;
  border: 0.5em solid #ffffff;
  opacity: 0.8;
}
.photo-button .circle,
.photo-button .ring {
  transition: all 0.25s;
}
.photo-button:active .ring {
  opacity: 1;
}
.photo-button:active .circle {
  opacity: 0.5;
}
