.container_cents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  color: var(--primary-color);
  font-size: 0.88rem;
}

.container_cents_input {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 10px;
}

.container_cents_input > span {
  font-size: 1.2rem;
  /* color: black; */
}
