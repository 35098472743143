:root {
  --primary-color: #00acde;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container-box {
  background-color: white;
  border-radius: 4px;
  padding: 2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
}

.gap {
  gap: 2rem;
}

.logo {
  width: 50px;
  display: block;
  margin: 0 auto;
}

.container-box-main {
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  text-underline-offset: 0.6rem;
  text-decoration-thickness: 0.2rem;
  text-align: center;
  font-size: larger;
}
h2 {
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
  text-underline-offset: 0.6rem;
  text-decoration-thickness: 0.18rem;
  font-size: medium;
  width: 100%;
}

.slogan-text {
  font-size: 0.8rem;
  color: var(--primary-color);
}

.subtitle {
  font-size: 0.8rem;
  text-align: center;
  margin: 1rem 0;
}

form {
  width: 100%;
  display: grid;
  gap: 1.2rem;
  padding: 2rem 0;
}

.back-button {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
}

.nav-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 5;
}

.person-card-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.person-card {
  background-color: white;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
}
.person-card p {
  font-size: 0.8rem;
  margin: 0;
  color: gray;
}
.person-card h3 {
  font-size: 1rem;
  margin: 0.3rem 0;
}

.logout-box {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.version {
  font-size: 0.8rem;
  color: silver;
  text-align: center;
}

.disclaimer-box {
  height: calc(100vh - 4rem);
  line-height: 1.5rem;
}

.disclaimer-box button {
  margin: 1.5rem auto 4rem auto;
}

.product-info {
  padding: 15px 20px;
  padding-right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: gray;
  font-size: 0.9rem;
}

.product-info span:first-child {
  color: black;
  font-size: 1.1rem;
  margin-bottom: 4px;
}

.product-quantity {
  gap: 10px;
  display: flex;
  align-items: center;

  padding: 5px 10px;
}
.product-quantity span {
  font-size: 1.2rem;
  font-weight: 600;
}

.product-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
}
.products-list {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #cbcbcb;
  margin-bottom: 40px;
}

.report-container {
  width: 100%;
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.report {
  background-color: white;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.report p {
  font-size: 0.8rem;
  color: gray;
  margin: 0;
}
.report span {
  font-size: 1.4rem;
  font-weight: bold;
}

.report .icon {
  background-color: #f4f4f4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
}

.report-quantity {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bar-backgound {
  background-color: var(--primary-color);
  position: absolute;
  height: 30px;
  border-radius: 5px;
}

.report strong {
  color: var(--primary-color);
}

.menu-donazioni {
  background-color: white;
  display: flex;
  padding: 10px;
  border-radius: 9px;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.menu-donazioni div {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 6px;
  font-weight: bold;
  font-size: 0.8rem;
}

.menu-donazioni .selected {
  background-color: var(--primary-color);
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-button {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: 0.2s;
  cursor: pointer;
  min-height: 135px;
  -webkit-box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.15);
}

.icon-button:hover {
  background-color: #f8f8f8;
}

.icon-button span {
  color: #919191;
  font-size: 1.3rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

@media only screen and (max-width: 600px) {
  .icon-button {
    min-height: 100px;
    padding: 10px;
  }
  .icon-button span {
    font-size: 0.9rem;
  }
  .my-25 {
    display: none;
  }
  h1 {
    margin-top: 0;
  }
}
